import React, { PureComponent, useEffect, useMemo, useState } from 'react';
import { TerrariumV1 } from "../../../../../utils/constants/questionnaire/versions";
import { getUrlParameter } from "../../../../../utils/utility";
import { API_URL, SERVICE_CONFIG_QUESTIONNAIRE } from "../../../../../utils/service-commons";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { set } from 'lodash';


const ModalTerrariumV1 = ({ props }) => {
   const { questionnaireContext } = props;
   const [showModal, setShowModal] = useState(true);
   const [newInvestorFundData, setNewInvestorFundData] = useState(null);
   const showTerrariumModal = getUrlParameter('terrariumModal');
   const terrariumSubscribedPerson = questionnaireContext && questionnaireContext.investorFund && questionnaireContext.investorFund.generalInfo && 
   questionnaireContext.investorFund.generalInfo.terrariumSubscribedPerson ? questionnaireContext.investorFund.generalInfo.terrariumSubscribedPerson : null;
   
   const [subscribedPerson, setSubscribedPerson] = useState(terrariumSubscribedPerson);
   const fund = questionnaireContext && questionnaireContext.fund || {}

   const saveSubscribedPerson = async (value) => {
      if (showTerrariumModal) {
          sessionStorage.removeItem('iq-last-step')
      }
      try {
         const res = await axios.post(
            `${API_URL()}/access/generalInfo/field`,
            {
            investorFund: questionnaireContext.investorFund,
            limitedPartnerFund: questionnaireContext.investorFund,
            updateField: 'terrariumSubscribedPerson',
            value,
            },
            SERVICE_CONFIG_QUESTIONNAIRE()
         );
         if (res.data.success) {
            setSubscribedPerson(value);
            res.data.investorFund.generalInfo.terrariumSubscribedPerson = value;
            setNewInvestorFundData(res.data.investorFund);
         }
      } catch (err) {
         props.logQuestionnaireError(err, props.questionnaireContext);
      }
   };

   const closeModal = () => {
      if (subscribedPerson === null) return
      setShowModal(false);
      if (newInvestorFundData) {
         props.setInvestorFund(newInvestorFundData);
      }

      if (subscribedPerson === 'no') {
         props.history.push('/access/home/instructions');
      }
      if (subscribedPerson === 'yes') {
         props.history.push('/access/home/information');
      }
   }

   if (!showModal) {
      return null
   }

   if (terrariumSubscribedPerson !== null && !showTerrariumModal) {
      return null
   }
   
   return (
         <div className='react-confirm-alert-overlay' style={{ zIndex: '1200'}}>
            <form className="text-center" style={{ padding: '20px', width: '600px', height: 'auto', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}>
            <h3 style={{ color: '#285E83', fontSize: '16px' }}><b>{fund.fundLegalName}</b></h3>
               {/* <p style={{margin: '10px 0px'}} >before you begin, please answer the below question to help us direct you to the appropriate questionnaire.</p> */}
            <h4 style={{ fontSize: '15px', lineHeight: '1.1', textAlign: 'left' }}>
            <b>
               Has the subscribing person or entity previously subscribed for an interest in Company Ventures Terrarium Fund I, LP (“Fund I”) and does such person or entity certify that the responses, information, representations and warranties contained in the Fund I Investor Questionnaire submitted by such person or entity are true, accurate and complete in all material respects as of the date hereof?
            </b></h4>
            <fieldset style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>

            <div style={{ marginRight: '50px', marginLeft: '15px' }}>
               <input type="radio" id="yes" 
                  name="terrariumSubscribedPerson"
                  value="yes" 
                  style={{ cursor: 'pointer' }}
                  defaultChecked={terrariumSubscribedPerson === 'yes'} 
                  onChange={(e) => { 
                     saveSubscribedPerson(e.target.value)
                  }}  
               />
               <label for="yes" style={{ width: 'auto', cursor: 'pointer' }}>Yes</label> 
            </div>
            <div>
               <input type="radio" id="no" 
                  name="terrariumSubscribedPerson" 
                  value="no" 
                  style={{ cursor: 'pointer' }}
                  defaultChecked={terrariumSubscribedPerson === 'no'} 
                  onChange={(e) => {
                     saveSubscribedPerson(e.target.value)
                  }}  
               />
               <label for="no" style={{ width: 'auto', cursor: 'pointer' }}>No</label>
            </div>
            </fieldset>

            <div  className={`button-submit-continue ${subscribedPerson !== null ? 'cursor-pointer' : 'cursor-not-allowed'}`} 
               style={{marginTop: '40px'} } 
               onClick={() => closeModal()}>
               Submit and Continue
            </div>
         </form>
      </div>
      )

}

export default ModalTerrariumV1